import { HttpErrorResponse } from '@angular/common/http';
import { TypedAction } from '@ngrx/store/src/models';
import { UNAUTHORIZED_CODE_ERROR } from '@app/core/constants/error';
import { of } from 'rxjs';
import { AuthenticationsAction } from '@app/store/actions';

export class ErrorsUtils {
  static catchErrorFormWithRedirection(
    error: string | HttpErrorResponse | Error,
    action: TypedAction<string>
  ) {
    if (
      error instanceof HttpErrorResponse &&
      error.status == UNAUTHORIZED_CODE_ERROR
    ) {
      return of(AuthenticationsAction.goToLogin());
    }

    return of(action);
  }
}
