import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@app/core/guards';

const routes: Routes = [
  { path: '', redirectTo: 'list-vehicle', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./features/authentication/authentication.module').then(
        m => m.AuthenticationModule
      ),
  },
  {
    path: 'list-vehicle',
    loadChildren: () =>
      import('./features/list-vehicle/list-vehicle.module').then(
        m => m.ListVehicleModule
      ),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'add-vehicle',
    loadChildren: () =>
      import('./features/add-vehicle/add-vehicle.module').then(
        m => m.AddVehicleModule
      ),
  },
  {
    path: 'Porteur/:action',
    loadChildren: () =>
      import('./features/carrier/carrier.module').then(m => m.CarrierModule),
  },
  {
    path: 'Remorque-essieux-median/:action',
    loadChildren: () =>
      import(
        '@app/features/center-axle-trailer/center-axle-trailer.module'
      ).then(m => m.CenterAxleTrailerModule),
  },
  {
    path: 'Remorque-avant-train/:action',
    loadChildren: () =>
      import('@app/features/draw-bar-trailer/draw-bar-trailer.module').then(
        m => m.DrawBarTrailerModule
      ),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'result-page',
    loadChildren: () =>
      import('./features/result-page/result-page.module').then(
        m => m.ResultPageModule
      ),
  },
  {
    path: 'convoys',
    loadChildren: () =>
      import('./features/convoys/convoys.module').then(m => m.ConvoysModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
