import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotifyService } from '@app/core/services';
import { NotifyAction } from '@app/store/actions/notify.actions';
import { catchError, map, mergeMap } from 'rxjs';
import { ErrorsUtils } from '@app/core/utils/errors-utils';

@Injectable()
export class NotifyEffects {
  constructor(
    private actions$: Actions,
    private notifyService: NotifyService
  ) {}

  saveNotify$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotifyAction.saveNotify),
      mergeMap(action =>
        this.notifyService.postNotify(action.notify).pipe(
          map(notify => NotifyAction.saveNotifySuccess({ notify })),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              NotifyAction.saveNotifyError({
                error: 'error notify',
              })
            )
          )
        )
      )
    );
  });
}
