import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Brand, Silhouette, Standard } from '@app/core/types';
import { DrawBarTrailer } from '@app/core/models';

export const DrawBarTrailerActions = createActionGroup({
  source: 'drawBarTrailer',
  events: {
    'load draw bar trailer component': emptyProps(),
    'init draw bar trailer success': emptyProps(),
    'load brand': emptyProps(),
    'load brand success': props<{ brands: Brand[] }>(),
    'load silhouette': emptyProps(),
    'load silhouette success': props<{ silhouettes: Silhouette[] }>(),
    'load standard': emptyProps(),
    'load standard success': props<{ standards: Standard[] }>(),
    'load failure error form': props<{ errorMessage: string }>(),
    'save draw bar trailer': props<{
      drawBarTrailer: DrawBarTrailer;
    }>(),
    'save draw bar trailer success': props<{ successMessage: string }>(),
    'save draw bar trailer error': props<{ error: string }>(),
    'update draw bar trailer': props<{
      drawbarTrailer: DrawBarTrailer;
    }>(),
    'update draw bar trailer success': props<{ successMessage: string }>(),
    'update draw bar trailer error': props<{ error: string }>(),
    'destroy draw bar trailer': emptyProps(),
  },
});
