import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generateUrlCarrier',
  pure: true,
})
export class GenerateUrlCarrierPipe implements PipeTransform {
  transform(silhouette: string): string {
    if (!silhouette) {
      return '';
    }
    return silhouette
      .toLowerCase()
      .replaceAll(' ', '')
      .replaceAll(' ', '-')
      .replace('', 'carrier-');
  }
}
