import { createReducer, on } from '@ngrx/store';
import {
  Brand,
  Coupling,
  Hook,
  Silhouette,
  Standard,
  Suspension,
} from '@app/core/types';
import { CarrierAction } from '@app/store/actions/carrier.actions';
import { ViewHook } from '@app/core/types/view-hook';

export const carrierFeatureKey = 'carrier';

export interface CarrierState {
  brands: Brand[];
  standards: Standard[];
  silhouettes: Silhouette[];
  couplings: Coupling[];
  suspensions: Suspension[];
  hooks: Hook[];
  viewHook: ViewHook;
  errorMessage?: string;
  loadingSubmitCarrier: boolean;
  loadingInitCarrierForm: boolean;
  successMessage?: string;
}

export const initialState: CarrierState = {
  brands: [],
  standards: [],
  silhouettes: [],
  couplings: [],
  suspensions: [],
  hooks: [],
  viewHook: {
    label: '',
    hooks: [],
  },
  loadingInitCarrierForm: true,
  loadingSubmitCarrier: false,
};

const CARRIER = 'Porteur';

export const carrierReducer = createReducer(
  initialState,
  on(
    CarrierAction.loadBrandSuccess,
    (state: CarrierState, { brands }): CarrierState => ({
      ...state,
      brands: brands.filter(brand => brand.typeBrand === CARRIER),
      loadingInitCarrierForm: true,
    })
  ),
  on(
    CarrierAction.intiCarrierSuccess,
    (state: CarrierState): CarrierState => ({
      ...state,
      loadingInitCarrierForm: false,
    })
  ),
  on(
    CarrierAction.destroyCarrier,
    (state: CarrierState): CarrierState => ({
      ...state,
      loadingInitCarrierForm: true,
      errorMessage: undefined,
    })
  ),
  on(
    CarrierAction.loadStandardSuccess,
    (state: CarrierState, { standards }): CarrierState => ({
      ...state,
      standards: standards,
    })
  ),
  on(
    CarrierAction.loadSilhouetteSuccess,
    (state: CarrierState, { silhouettes }): CarrierState => ({
      ...state,
      silhouettes,
    })
  ),
  on(
    CarrierAction.loadCouplingSuccess,
    (state: CarrierState, { couplings }): CarrierState => ({
      ...state,
      couplings,
    })
  ),
  on(
    CarrierAction.loadSuspensionSuccess,
    (state: CarrierState, { suspensions }): CarrierState => ({
      ...state,
      suspensions,
    })
  ),
  on(
    CarrierAction.loadHookSuccess,
    (state: CarrierState, { hooks }): CarrierState => ({
      ...state,
      hooks: hooks,
      viewHook: {
        ...state.viewHook,
        hooks: state.viewHook.label
          ? hooks.filter(hook => hook.typeCoupling === state.viewHook.label)
          : [],
      },
    })
  ),
  on(
    CarrierAction.selectHookByCoupling,
    (state: CarrierState, { couplingName }): CarrierState => ({
      ...state,
      viewHook: {
        label:
          couplingName === 'Fin de châssis' ? 'Fin de châssis' : 'Sous pont',
        hooks: state.hooks.filter(hook => hook.typeCoupling === couplingName),
      },
      successMessage: undefined,
    })
  ),
  on(
    CarrierAction.loadFailureErrorForm,
    (state: CarrierState, { errorMessage }): CarrierState => ({
      ...state,
      errorMessage,
    })
  ),
  on(
    CarrierAction.saveCarrier,
    (state: CarrierState): CarrierState => ({
      ...state,
      loadingSubmitCarrier: true,
    })
  ),
  on(
    CarrierAction.saveCarrierSuccess,
    (state: CarrierState, { successMessage }): CarrierState => ({
      ...state,
      loadingSubmitCarrier: false,
      successMessage: successMessage,
    })
  ),
  on(
    CarrierAction.saveCarrierError,
    (state: CarrierState, { error }): CarrierState => ({
      ...state,
      loadingSubmitCarrier: false,
      errorMessage: error,
    })
  ),
  on(
    CarrierAction.updateCarrierSuccess,
    (state: CarrierState, { successMessage }): CarrierState => ({
      ...state,
      loadingSubmitCarrier: false,
      successMessage: successMessage,
    })
  ),
  on(
    CarrierAction.updateCarrierError,
    (state: CarrierState, { error }): CarrierState => ({
      ...state,
      loadingSubmitCarrier: false,
      errorMessage: error,
    })
  )
);
