import { Brand, Standard } from '@app/core/types';

export class DrawBarTrailer {
  parkInternalCode?: string;
  brand?: Brand;
  otherBrand?: string;
  silhouette?: string;
  type?: string;
  otherSilhouette?: string;
  mmta?: number;
  licencePlate?: string;
  serialNumber?: string;
  otherInformation?: string;
  standard?: Standard;
  otherStandard?: string;
  id?: number;
}
