<div class="fleet-navbar">
  <a routerLink="/list-vehicle" class="disabled-link">
    <div class="icon-calculator" (click)="activeIcon('calculator')" [class.icon-active]="iconSelected === 'calculator'">
      <fa-icon [icon]="faCalculator"></fa-icon>
      <span class="text-icon-calculator">Calculateur</span>
    </div>
  </a>
  <a routerLink="/add-vehicle" class="disabled-link">
    <div class="icon-vehicle" (click)="activeIcon('vehicle')" [class.icon-active]="iconSelected === 'vehicle'">
      <fa-icon [icon]="faPlus"></fa-icon>
      <span class="text-icon-vehicle">Véhicules</span>
    </div>
  </a>
  <a routerLink="/convoys" class="disabled-link">
    <div class="icon-vehicle" (click)="activeIcon('convoy')" [class.icon-active]="iconSelected === 'convoy'">
      <fa-icon [icon]="faTruckArrowRight"></fa-icon>
      <span class="text-icon-vehicle">Convoi</span>
    </div>
  </a>
  <div class="icon-logout" (click)="logout(); activeIcon('logout')" [class.icon-active]="iconSelected === 'logout'">
    <fa-icon [icon]="faArrowRightFromBracket"></fa-icon>
    <span class="text-icon-logout">Déconnexion</span>
  </div>
</div>
