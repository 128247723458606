import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generateUrlCenterAxleTrailer',
  pure: true,
})
export class GenerateUrlCenterAxleTrailerPipe implements PipeTransform {
  transform(silhouette?: string): string {
    if (!silhouette) {
      return '';
    }
    return silhouette
      .toLowerCase()
      .replaceAll(' ', '')
      .replaceAll(' ', '-')
      .replace('', 'center-axle-trailer-');
  }
}
