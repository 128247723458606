import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BrandService } from '@app/core/services/brand.service';
import { StandardService } from '@app/core/services/standard.service';
import { DrawBarTrailerService } from '@app/core/services/draw-bar-trailer.service';
import {
  messageErrorForm,
  messageErrorSubmit,
  messageSuccessDrawbarTrailerSubmit,
} from '@app/core/constants/messages';
import { catchError, map, mergeMap, tap } from 'rxjs';
import { DrawBarTrailerActions } from '@app/store/actions/draw-bar-trailer.actions';
import { ErrorsUtils } from '@app/core/utils/errors-utils';
import { Router } from '@angular/router';

@Injectable()
export class DrawBarTrailerEffect {
  constructor(
    private actions$: Actions,
    private brandService: BrandService,
    private standardService: StandardService,
    private drawBarTrailerService: DrawBarTrailerService,
    private router: Router
  ) {}

  loadDrawBarTrailerComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DrawBarTrailerActions.loadDrawBarTrailerComponent),
      map(() => DrawBarTrailerActions.loadBrand())
    );
  });

  loadBrand$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DrawBarTrailerActions.loadBrand),
      mergeMap(() =>
        this.brandService.getBrands().pipe(
          map(brands => DrawBarTrailerActions.loadBrandSuccess({ brands })),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              DrawBarTrailerActions.loadFailureErrorForm({
                errorMessage: messageErrorForm,
              })
            )
          )
        )
      )
    );
  });

  loadBrandSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DrawBarTrailerActions.loadBrandSuccess),
      map(() => DrawBarTrailerActions.loadStandard())
    );
  });

  loadStandard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DrawBarTrailerActions.loadStandard),
      mergeMap(() =>
        this.standardService.getStandards().pipe(
          map(standards =>
            DrawBarTrailerActions.loadStandardSuccess({ standards })
          ),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              DrawBarTrailerActions.loadFailureErrorForm({
                errorMessage: messageErrorForm,
              })
            )
          )
        )
      )
    );
  });

  loadStandardSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DrawBarTrailerActions.loadStandardSuccess),
      map(() => DrawBarTrailerActions.loadSilhouette())
    );
  });

  loadSilhouette$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DrawBarTrailerActions.loadSilhouette),
      mergeMap(() =>
        this.drawBarTrailerService.getSilhouettes().pipe(
          map(silhouettes =>
            DrawBarTrailerActions.loadSilhouetteSuccess({ silhouettes })
          ),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              DrawBarTrailerActions.loadFailureErrorForm({
                errorMessage: messageErrorForm,
              })
            )
          )
        )
      )
    );
  });
  loadSilhouetteSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DrawBarTrailerActions.loadSilhouetteSuccess),
      map(() => DrawBarTrailerActions.initDrawBarTrailerSuccess())
    );
  });

  saveDrawBarTrailer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DrawBarTrailerActions.saveDrawBarTrailer),
      mergeMap(action =>
        this.drawBarTrailerService
          .addDrawbarTrailer(action.drawBarTrailer)
          .pipe(
            map(() =>
              DrawBarTrailerActions.saveDrawBarTrailerSuccess({
                successMessage: messageSuccessDrawbarTrailerSubmit,
              })
            ),
            tap(() =>
              setTimeout(() => {
                this.router.navigate(['/list-vehicle'], {
                  queryParams: { selectedTabIndex: 1 },
                });
              }, 2000)
            ),
            catchError(error =>
              ErrorsUtils.catchErrorFormWithRedirection(
                error,
                DrawBarTrailerActions.saveDrawBarTrailerError({
                  error: messageErrorSubmit,
                })
              )
            )
          )
      )
    );
  });
  updateDrawbarTrailer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DrawBarTrailerActions.updateDrawBarTrailer),
      mergeMap(action =>
        this.drawBarTrailerService
          .updateDrawbarTrailer(action.drawbarTrailer)
          .pipe(
            map(() =>
              DrawBarTrailerActions.updateDrawBarTrailerSuccess({
                successMessage: messageSuccessDrawbarTrailerSubmit,
              })
            ),
            tap(() =>
              setTimeout(() => {
                this.router.navigate(['/list-vehicle'], {
                  queryParams: { selectedTabIndex: 0 },
                });
              }, 2000)
            ),
            catchError(error =>
              ErrorsUtils.catchErrorFormWithRedirection(
                error,
                DrawBarTrailerActions.updateDrawBarTrailerError({
                  error: messageErrorSubmit,
                })
              )
            )
          )
      )
    );
  });
}
