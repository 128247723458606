import { createReducer, on } from '@ngrx/store';
import { Brand, Silhouette, Standard } from '@app/core/types';
import { CenterAxleTrailerActions } from '@app/store/actions/center-axle-trailer.actions';
import { TRAILER } from '@app/store/reducers/draw-bar-trailer.reducer';

export const centerAxleTrailerFeatureKey = 'centerAxleTrailer';

export interface centerAxleTrailerState {
  brands: Brand[];
  standards: Standard[];
  silhouettes: Silhouette[];
  errorMessage?: string;
  successMessage?: string;
  loadingSubmitCenterAxleTrailer: boolean;
  loadingInitCenterAxleTrailerForm: boolean;
}

export const initialState: centerAxleTrailerState = {
  brands: [],
  standards: [],
  silhouettes: [],
  loadingSubmitCenterAxleTrailer: false,
  loadingInitCenterAxleTrailerForm: true,
};

export const centerAxleTrailerReducer = createReducer(
  initialState,
  on(
    CenterAxleTrailerActions.loadBrandSuccess,
    (state: centerAxleTrailerState, { brands }): centerAxleTrailerState => ({
      ...state,
      brands: brands.filter(brand => brand.typeBrand === TRAILER),
    })
  ),
  on(
    CenterAxleTrailerActions.loadStandardSuccess,
    (state: centerAxleTrailerState, { standards }): centerAxleTrailerState => ({
      ...state,
      standards: standards,
    })
  ),
  on(
    CenterAxleTrailerActions.loadSilhouetteSuccess,
    (
      state: centerAxleTrailerState,
      { silhouettes }
    ): centerAxleTrailerState => ({
      ...state,
      silhouettes,
    })
  ),
  on(
    CenterAxleTrailerActions.loadFailureErrorForm,
    (
      state: centerAxleTrailerState,
      { errorMessage }
    ): centerAxleTrailerState => ({
      ...state,
      errorMessage,
    })
  ),
  on(
    CenterAxleTrailerActions.saveCenterAxleTrailer,
    (state: centerAxleTrailerState): centerAxleTrailerState => ({
      ...state,
      loadingSubmitCenterAxleTrailer: true,
    })
  ),
  on(
    CenterAxleTrailerActions.saveCenterAxleTrailerSuccess,
    (
      state: centerAxleTrailerState,
      { successMessage }
    ): centerAxleTrailerState => ({
      ...state,
      loadingSubmitCenterAxleTrailer: false,
      successMessage: successMessage,
    })
  ),
  on(
    CenterAxleTrailerActions.saveCenterAxleTrailerError,
    (state: centerAxleTrailerState, { error }): centerAxleTrailerState => ({
      ...state,
      errorMessage: error,
      loadingSubmitCenterAxleTrailer: false,
    })
  ),
  on(
    CenterAxleTrailerActions.initCenterAxleTrailerSuccess,
    (state: centerAxleTrailerState): centerAxleTrailerState => ({
      ...state,
      loadingInitCenterAxleTrailerForm: false,
    })
  ),
  on(
    CenterAxleTrailerActions.destroyCenterAxleTrailer,
    (state: centerAxleTrailerState): centerAxleTrailerState => ({
      ...state,
      loadingInitCenterAxleTrailerForm: true,
      errorMessage: undefined,
    })
  ),
  on(
    CenterAxleTrailerActions.updateCenterAxleTrailerSuccess,
    (
      state: centerAxleTrailerState,
      { successMessage }
    ): centerAxleTrailerState => ({
      ...state,
      loadingSubmitCenterAxleTrailer: false,
      successMessage: successMessage,
    })
  ),
  on(
    CenterAxleTrailerActions.updateCenterAxleTrailerError,
    (state: centerAxleTrailerState, { error }): centerAxleTrailerState => ({
      ...state,
      loadingSubmitCenterAxleTrailer: false,
      errorMessage: error,
    })
  )
);
