import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  authenticationFeatureKey,
  authenticationReducer,
} from '@app/store/reducers/authentication.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationEffects } from '@app/store/effects/authentication.effect';
import { CoreModule } from '@app/core/core.module';
import {
  carrierFeatureKey,
  carrierReducer,
} from '@app/store/reducers/carrier.reducer';
import { CarrierEffects } from '@app/store/effects/carrier.effects';
import {
  centerAxleTrailerFeatureKey,
  centerAxleTrailerReducer,
} from '@app/store/reducers/center-axle-trailer.reducer';
import { CenterAxleTrailerEffect } from '@app/store/effects/center-axle-trailer.effect';
import {
  drawBarTrailerFeatureKey,
  drawBarTrailerReducer,
} from '@app/store/reducers/draw-bar-trailer.reducer';
import { DrawBarTrailerEffect } from '@app/store/effects/draw-bar-trailer.effect';
import {
  vehicleFeatureKey,
  vehicleReducer,
} from '@app/store/reducers/vehicle.reducer';
import { VehicleEffects } from '@app/store/effects/vehicle.effects';
import { ShareModule } from './share';
// import {
//   notifyFeatureKey,
//   notifyReducer,
// } from '@app/store/reducers/notify.reducer';
import { NotifyEffects } from '@app/store/effects/notify.effects';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ShareModule,
    StoreModule.forRoot(
      {
        [authenticationFeatureKey]: authenticationReducer,
        [carrierFeatureKey]: carrierReducer,
        [vehicleFeatureKey]: vehicleReducer,
        [centerAxleTrailerFeatureKey]: centerAxleTrailerReducer,
        [drawBarTrailerFeatureKey]: drawBarTrailerReducer,
        // [notifyFeatureKey]: notifyReducer,
      },
      {}
    ),
    CoreModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    EffectsModule.forRoot([
      AuthenticationEffects,
      CarrierEffects,
      CenterAxleTrailerEffect,
      DrawBarTrailerEffect,
      VehicleEffects,
      NotifyEffects,
    ]),
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
