import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs';
import { CenterAxleTrailerActions } from '@app/store/actions/center-axle-trailer.actions';
import { BrandService } from '@app/core/services/brand.service';
import { StandardService } from '@app/core/services/standard.service';
import {
  messageErrorForm,
  messageErrorSubmit,
  messageSuccessCenterAxleTrailerSubmit,
} from '@app/core/constants/messages';
import { CenterAxleTrailerService } from '@app/core/services';
import { ErrorsUtils } from '@app/core/utils/errors-utils';
import { Router } from '@angular/router';

@Injectable()
export class CenterAxleTrailerEffect {
  constructor(
    private actions$: Actions,
    private centerAxleTrailerService: CenterAxleTrailerService,
    private brandService: BrandService,
    private standardService: StandardService,
    private router: Router
  ) {}

  loadCenterAxleTrailerComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CenterAxleTrailerActions.loadCenterAxleTrailerComponent),
      map(() => CenterAxleTrailerActions.loadBrand())
    );
  });

  loadBrand$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CenterAxleTrailerActions.loadBrand),
      mergeMap(() =>
        this.brandService.getBrands().pipe(
          map(brands => CenterAxleTrailerActions.loadBrandSuccess({ brands })),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              CenterAxleTrailerActions.loadFailureErrorForm({
                errorMessage: messageErrorForm,
              })
            )
          )
        )
      )
    );
  });

  loadBrandSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CenterAxleTrailerActions.loadBrandSuccess),
      map(() => CenterAxleTrailerActions.loadStandard())
    );
  });

  loadStandard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CenterAxleTrailerActions.loadStandard),
      mergeMap(() =>
        this.standardService.getStandards().pipe(
          map(standards =>
            CenterAxleTrailerActions.loadStandardSuccess({ standards })
          ),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              CenterAxleTrailerActions.loadFailureErrorForm({
                errorMessage: messageErrorForm,
              })
            )
          )
        )
      )
    );
  });

  loadStandardSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CenterAxleTrailerActions.loadStandardSuccess),
      map(() => CenterAxleTrailerActions.loadSilhouette())
    );
  });

  loadSilhouette$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CenterAxleTrailerActions.loadSilhouette),
      mergeMap(() =>
        this.centerAxleTrailerService.getSilhouettes().pipe(
          map(silhouettes =>
            CenterAxleTrailerActions.loadSilhouetteSuccess({ silhouettes })
          ),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              CenterAxleTrailerActions.loadFailureErrorForm({
                errorMessage: messageErrorForm,
              })
            )
          )
        )
      )
    );
  });

  loadSilhouetteSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CenterAxleTrailerActions.loadSilhouetteSuccess),
      map(() => CenterAxleTrailerActions.initCenterAxleTrailerSuccess())
    );
  });

  saveCenterAxleTrailer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CenterAxleTrailerActions.saveCenterAxleTrailer),
      mergeMap(action =>
        this.centerAxleTrailerService
          .addCenterAxleTrailer(action.centerAxleTrailer)
          .pipe(
            map(() =>
              CenterAxleTrailerActions.saveCenterAxleTrailerSuccess({
                successMessage: messageSuccessCenterAxleTrailerSubmit,
              })
            ),
            tap(() =>
              setTimeout(() => {
                this.router.navigate(['/list-vehicle'], {
                  queryParams: { selectedTabIndex: 1 },
                });
              }, 2000)
            ),
            catchError(error =>
              ErrorsUtils.catchErrorFormWithRedirection(
                error,
                CenterAxleTrailerActions.saveCenterAxleTrailerError({
                  error: messageErrorSubmit,
                })
              )
            )
          )
      )
    );
  });

  updateCenterAxleTrailer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CenterAxleTrailerActions.updateCenterAxleTrailer),
      mergeMap(action =>
        this.centerAxleTrailerService
          .updateCenterAxleTrailer(action.centerAxleTrailer)
          .pipe(
            map(() =>
              CenterAxleTrailerActions.updateCenterAxleTrailerSuccess({
                successMessage: messageSuccessCenterAxleTrailerSubmit,
              })
            ),
            tap(() =>
              setTimeout(() => {
                this.router.navigate(['/list-vehicle'], {
                  queryParams: { selectedTabIndex: 0 },
                });
              }, 2000)
            ),
            catchError(error =>
              ErrorsUtils.catchErrorFormWithRedirection(
                error,
                CenterAxleTrailerActions.updateCenterAxleTrailerError({
                  error: messageErrorSubmit,
                })
              )
            )
          )
      )
    );
  });
}
