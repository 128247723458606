import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Convoy } from '@app/core/models/convoy';
import { Carrier } from '@app/core/types/carrier';
import { CenterAxleTrailer, DrawBarTrailer } from '@app/core/models';

export const VehicleAction = createActionGroup({
  source: 'Vehicle',
  events: {
    'load vehicles component': emptyProps(),
    'load carriers': emptyProps(),
    'load carriers success': props<{ carriers: Carrier[] }>(),
    'load center axle trailers': emptyProps(),
    'load center axle trailers success': props<{
      centerAxleTrailers: CenterAxleTrailer[];
    }>(),
    'load drawBar trailers': emptyProps(),
    'load drawBar trailers success': props<{
      drawBarTrailers: DrawBarTrailer[];
    }>(),
    'update convoy ': props<{ convoy: Convoy }>(),
    'update convoy success': props<{
      result: Convoy;
      successMessage: string;
    }>(),
    'update convoy error': props<{ error: string }>(),
    'load convoy': emptyProps(),
    'load convoy success': props<{ convoy: Convoy[] }>(),
    'load convoy error': props<{ error: string }>(),
    'add number case and date to convoy': props<{
      caseNumber: string;
      rentalDateStart: string;
      rentalDateEnd: string;
    }>(),
    'save convoy': props<{ convoy: Convoy }>(),
    'save convoy success': props<{ result: Convoy }>(),
    'save convoy error': props<{ error: string }>(),
    'carrier selected': props<{ index: number }>(),
    'centerAxleTrailer selected': props<{ index: number }>(),
    'draw bar trailer selected': props<{ index: number }>(),
  },
});
