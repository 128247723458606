import { Component } from '@angular/core';
import { selectIsAuthenticated } from '@app/store/selector';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthenticationsAction } from '@app/store/actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  isLogged$: Observable<boolean>;

  constructor(private store: Store) {
    this.store.dispatch(AuthenticationsAction.getStatus());
    this.isLogged$ = this.store.select(selectIsAuthenticated);
  }
}
