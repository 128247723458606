import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generateUrlDrawbarTrailer',
  pure: true,
})
export class GenerateUrlDrawbarTrailerPipe implements PipeTransform {
  transform(silhouette?: string): string {
    if (!silhouette) {
      return '';
    }
    return silhouette
      .toLowerCase()
      .replaceAll(' ', '')
      .replaceAll(' ', '-')
      .replace('', 'draw-bar-trailer-');
  }
}
