import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Silhouette } from '@app/core/types';
import { environment } from '@env/environment';
import { DrawBarTrailer } from '@app/core/models';

@Injectable({
  providedIn: 'root',
})
export class DrawBarTrailerService {
  constructor(private http: HttpClient) {}

  addDrawbarTrailer(
    drawbarTrailer: DrawBarTrailer
  ): Observable<DrawBarTrailer> {
    const url = `${environment.apiUrl}/api/drawbar_trailers`;
    return this.http.post<DrawBarTrailer>(url, drawbarTrailer);
  }
  getSilhouettes(): Observable<Silhouette[]> {
    const url = `${environment.apiUrl}/api/silhouette_drawbar_trailer_enums`;
    return this.http.get<string[]>(url).pipe(
      map((silhouettes: string[]) =>
        silhouettes.map(silhouette => {
          return {
            name: `${silhouette}`,
            img: `${silhouette
              .toLowerCase()
              .replaceAll(' ', '')
              .replace('', 'draw-bar-trailer-')}.svg`,
            alt: `${silhouette}`,
          };
        })
      )
    );
  }
  updateDrawbarTrailer(
    drawbarTrailer: DrawBarTrailer
  ): Observable<DrawBarTrailer> {
    const url = `${environment.apiUrl}/api/drawbar_trailers/${drawbarTrailer.id}`;
    return this.http.put<DrawBarTrailer>(url, drawbarTrailer);
  }
}
