import { createReducer, on } from '@ngrx/store';
import { VehicleAction } from '@app/store/actions/vehicle.actions';
import { Convoy } from '@app/core/models/convoy';
import { Carrier } from '@app/core/types/carrier';
import { CenterAxleTrailer, DrawBarTrailer } from '@app/core/models';

export const vehicleFeatureKey = 'vehicle';

export interface VehicleState {
  carriers: Carrier[];
  carrierSelected?: Carrier;
  centerAxleTrailerSelected?: CenterAxleTrailer;
  drawbarTrailerSelected?: DrawBarTrailer;
  centerAxleTrailers: CenterAxleTrailer[];
  drawBarTrailers: DrawBarTrailer[];
  errorMessage?: string;
  successMessage: string;
  loadingSubmitConvoy: boolean;
  result: Convoy;
  convoy: Convoy[];
}

export const initialState: VehicleState = {
  carriers: [],
  centerAxleTrailers: [],
  drawBarTrailers: [],
  loadingSubmitConvoy: false,
  result: {},
  convoy: [],
  successMessage: '',
};

export const vehicleReducer = createReducer(
  initialState,
  on(
    VehicleAction.loadCarriersSuccess,
    (state: VehicleState, { carriers }): VehicleState => ({
      ...state,
      carriers: carriers,
    })
  ),
  on(
    VehicleAction.loadCenterAxleTrailersSuccess,
    (state: VehicleState, { centerAxleTrailers }): VehicleState => ({
      ...state,
      centerAxleTrailers: centerAxleTrailers,
    })
  ),
  on(
    VehicleAction.loadDrawbarTrailersSuccess,
    (state: VehicleState, { drawBarTrailers }): VehicleState => ({
      ...state,
      drawBarTrailers: drawBarTrailers,
    })
  ),
  on(
    VehicleAction.saveConvoy,
    (state: VehicleState): VehicleState => ({
      ...state,
      loadingSubmitConvoy: true,
    })
  ),
  on(
    VehicleAction.saveConvoySuccess,
    (state: VehicleState, { result }): VehicleState => ({
      ...state,
      result: result,
      loadingSubmitConvoy: false,
    })
  ),
  on(
    VehicleAction.saveConvoyError,
    (state: VehicleState, { error }): VehicleState => ({
      ...state,
      errorMessage: error,
      loadingSubmitConvoy: false,
    })
  ),
  on(
    VehicleAction.loadConvoy,
    (state: VehicleState): VehicleState => ({
      ...state,
    })
  ),
  on(
    VehicleAction.loadConvoySuccess,
    (state: VehicleState, { convoy }): VehicleState => ({
      ...state,
      convoy: convoy,
    })
  ),
  on(
    VehicleAction.loadConvoyError,
    (state: VehicleState, { error }): VehicleState => ({
      ...state,
      errorMessage: error,
    })
  ),
  on(
    VehicleAction.updateConvoy,
    (state: VehicleState): VehicleState => ({
      ...state,
    })
  ),
  on(
    VehicleAction.updateConvoySuccess,
    (state: VehicleState, { result, successMessage }): VehicleState => ({
      ...state,
      result: result,
      successMessage: successMessage,
    })
  ),
  on(
    VehicleAction.updateConvoyError,
    (state: VehicleState, { error }): VehicleState => ({
      ...state,
      errorMessage: error,
    })
  ),
  on(
    VehicleAction.carrierSelected,
    (state: VehicleState, { index }): VehicleState => ({
      ...state,
      carrierSelected: state.carriers[index],
    })
  ),
  on(
    VehicleAction.addNumberCaseAndDateToConvoy,
    (
      state: VehicleState,
      { caseNumber, rentalDateStart, rentalDateEnd }
    ): VehicleState => ({
      ...state,
      result: { ...state.result, caseNumber, rentalDateStart, rentalDateEnd },
    })
  ),
  on(
    VehicleAction.centeraxletrailerSelected,
    (state: VehicleState, { index }): VehicleState => ({
      ...state,
      centerAxleTrailerSelected: state.centerAxleTrailers.find(
        trailer => trailer.id === index
      ),
    })
  ),
  on(
    VehicleAction.drawBarTrailerSelected,
    (state: VehicleState, { index }): VehicleState => ({
      ...state,
      drawbarTrailerSelected: state.drawBarTrailers.find(
        trailer => trailer.id === index
      ),
    })
  )
);
