import { createActionGroup, props, emptyProps } from '@ngrx/store';

import { User } from '@app/core/models';
import { Token } from '@app/core/types';

export const AuthenticationsAction = createActionGroup({
  source: 'Authentication',
  events: {
    Login: props<{ user: User }>(),
    'Login Success': props<{ result: Token }>(),
    'Login Failure': props<{ message: string }>(),
    'Go to login': emptyProps(),
    'Get status': emptyProps(),
    'Get status success': props<{ hasToken: boolean }>(),
  },
});
