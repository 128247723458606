import { createReducer, on } from '@ngrx/store';

import { User } from '@app/core/models';
import { AuthenticationsAction } from '@app/store/actions/authentication.actions';

export const authenticationFeatureKey = 'authentication';

export interface AuthenticationState {
  isAuthenticated: boolean;
  user: User | null;
  errorMessage: string | null;
  loading: boolean;
}

export const initialState: AuthenticationState = {
  isAuthenticated: false,
  user: null,
  errorMessage: null,
  loading: false,
};

export const authenticationReducer = createReducer(
  initialState,
  on(
    AuthenticationsAction.login,
    (state, { user }): AuthenticationState => ({
      ...state,
      user: user,
      loading: true,
      errorMessage: null,
    })
  ),
  on(
    AuthenticationsAction.loginSuccess,
    (state: AuthenticationState, { result }): AuthenticationState => ({
      ...state,
      isAuthenticated: true,
      user: { ...state.user, token: result.token },
      errorMessage: null,
      loading: false,
    })
  ),
  on(
    AuthenticationsAction.loginFailure,
    (state: AuthenticationState, { message }): AuthenticationState => ({
      ...state,
      isAuthenticated: false,
      user: { ...state.user, token: null },
      errorMessage: message,
      loading: false,
    })
  ),
  on(
    AuthenticationsAction.goToLogin,
    (state: AuthenticationState): AuthenticationState => ({
      ...state,
      isAuthenticated: false,
      user: { ...state.user, token: null },
      loading: false,
    })
  ),
  on(
    AuthenticationsAction.getStatusSuccess,
    (state: AuthenticationState, { hasToken }): AuthenticationState => ({
      ...state,
      isAuthenticated: hasToken,
      loading: false,
    })
  )
);
