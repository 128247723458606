import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Coupling, Hook, Silhouette, Suspension } from '@app/core/types';
import { map, Observable } from 'rxjs';
import { Carrier } from '@app/core/models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CarrierService {
  constructor(private http: HttpClient) {}
  addCarrier(carrier: Carrier): Observable<Carrier> {
    const url = `${environment.apiUrl}/api/carriers`;
    return this.http.post<Carrier>(url, carrier);
  }

  updateCarrier(carrier: Carrier): Observable<Carrier> {
    const url = `${environment.apiUrl}/api/carriers/${carrier.id}`;
    return this.http.put<Carrier>(url, carrier);
  }

  getSilhouettes(): Observable<Silhouette[]> {
    const url = `${environment.apiUrl}/api/silhouette_carrier_enums`;
    return this.http.get<string[]>(url).pipe(
      map((silhouettes: string[]) =>
        silhouettes.map(silhouette => {
          return {
            name: `${silhouette}`,
            img: `${silhouette
              .toLowerCase()
              .replaceAll(' ', '')
              .replaceAll(' ', '-')
              .replace('', 'carrier-')}.svg`,
            alt: `${silhouette}`,
          };
        })
      )
    );
  }
  getCouplings(): Observable<Coupling[]> {
    const url = `${environment.apiUrl}/api/coupling_carrier_enums`;
    return this.http.get<string[]>(url).pipe(
      map((couplings: string[]) =>
        couplings.map(coupling => {
          return {
            name: `${coupling}`,
            img: `${coupling
              .toLowerCase()
              .replaceAll('-', ' ')
              .replaceAll(' ', '-')}.svg`,
            alt: `${coupling}`,
          };
        })
      )
    );
  }
  getSuspensions(): Observable<Suspension[]> {
    const url = `${environment.apiUrl}/api/suspension_carrier_enums`;
    return this.http.get<string[]>(url).pipe(
      map((suspensions: string[]) =>
        suspensions.map(suspension => {
          return {
            name: `${suspension}`,
            img: `${suspension.toLowerCase()}.svg`,
            alt: `${suspension}`,
          };
        })
      )
    );
  }
  getHooks(): Observable<Hook[]> {
    const url = `${environment.apiUrl}/api/hooks`;
    return this.http.get<Hook[]>(url).pipe(
      map((hooks: Hook[]) =>
        hooks.map(hook => {
          return {
            id: hook.id,
            name: hook.name,
            img: `${hook.name.toLowerCase().replaceAll(' ', '-')}.svg`,
            alt: hook.alt,
            typeCoupling: hook.typeCoupling,
          };
        })
      )
    );
  }
}
