import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Token } from '@app/core/types';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  logIn(login?: string, password?: string): Observable<Token> {
    if (login === '' || password === '') {
      throw 'Le login et password sont obligatoire';
    }
    const url = `${this.BASE_URL}/auth`;
    return this.http.post<Token>(url, { login, password });
  }
}
