import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';

import { AuthenticationService } from '@app/core/services/authentication.service';
import { AuthenticationsAction } from '@app/store/actions/authentication.actions';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationsAction.login),
      mergeMap(action =>
        this.authenticationService
          .logIn(action.user.login, action.user.password)
          .pipe(
            map(result => AuthenticationsAction.loginSuccess({ result })),
            catchError((error: string | HttpErrorResponse | Error) => {
              let message;
              if (typeof error === 'string') {
                message = error;
              } else {
                message = error.message;
              }
              return of(AuthenticationsAction.loginFailure({ message }));
            })
          )
      )
    );
  });

  loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationsAction.loginSuccess),
        tap(action => {
          localStorage.setItem('token', action.result.token);
          this.router.navigateByUrl('/list-vehicle');
        })
      );
    },
    { dispatch: false }
  );

  goToLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationsAction.goToLogin),
        tap(() => {
          localStorage.removeItem('token');
          this.router.navigateByUrl('/login');
        })
      );
    },
    { dispatch: false }
  );

  getStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationsAction.getStatus),
      map(() =>
        AuthenticationsAction.getStatusSuccess({
          hasToken: this.authenticationService.getToken() != null,
        })
      )
    );
  });
}
