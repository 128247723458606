import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CarrierAction } from '@app/store/actions/carrier.actions';
import { catchError, map, mergeMap, tap } from 'rxjs';
import { CarrierService } from '@app/core/services';
import { BrandService } from '@app/core/services/brand.service';
import { StandardService } from '@app/core/services/standard.service';
import {
  messageErrorForm,
  messageErrorSubmit,
  messageSuccessCarrierSubmit,
} from '@app/core/constants/messages';
import { ErrorsUtils } from '@app/core/utils/errors-utils';
import { Router } from '@angular/router';

@Injectable()
export class CarrierEffects {
  constructor(
    private actions$: Actions,
    private carrierService: CarrierService,
    private brandService: BrandService,
    private standardService: StandardService,
    private router: Router
  ) {}

  loadCarrierComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadCarrierComponent),
      map(() => CarrierAction.loadBrand())
    );
  });

  loadBrand$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadBrand),
      mergeMap(() =>
        this.brandService.getBrands().pipe(
          map(brands => CarrierAction.loadBrandSuccess({ brands })),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              CarrierAction.loadFailureErrorForm({
                errorMessage: messageErrorForm,
              })
            )
          )
        )
      )
    );
  });

  loadBrandSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadBrandSuccess),
      map(() => CarrierAction.loadStandard())
    );
  });

  loadStandard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadStandard),
      mergeMap(() =>
        this.standardService.getStandards().pipe(
          map(standards => CarrierAction.loadStandardSuccess({ standards })),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              CarrierAction.loadFailureErrorForm({
                errorMessage: messageErrorForm,
              })
            )
          )
        )
      )
    );
  });

  loadStandardSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadStandardSuccess),
      map(() => CarrierAction.loadSilhouette())
    );
  });

  loadSilhouette$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadSilhouette),
      mergeMap(() =>
        this.carrierService.getSilhouettes().pipe(
          map(silhouettes =>
            CarrierAction.loadSilhouetteSuccess({ silhouettes })
          ),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              CarrierAction.loadFailureErrorForm({
                errorMessage: messageErrorForm,
              })
            )
          )
        )
      )
    );
  });

  loadSilhouetteSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadSilhouetteSuccess),
      map(() => CarrierAction.loadCoupling())
    );
  });

  loadCoupling$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadCoupling),
      mergeMap(() =>
        this.carrierService.getCouplings().pipe(
          map(couplings => CarrierAction.loadCouplingSuccess({ couplings })),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              CarrierAction.loadFailureErrorForm({
                errorMessage: messageErrorForm,
              })
            )
          )
        )
      )
    );
  });
  loadCouplingSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadCouplingSuccess),
      map(() => CarrierAction.loadSuspension())
    );
  });

  loadSuspension$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadSuspension),
      mergeMap(() =>
        this.carrierService.getSuspensions().pipe(
          map(suspensions =>
            CarrierAction.loadSuspensionSuccess({ suspensions })
          ),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              CarrierAction.loadFailureErrorForm({
                errorMessage: messageErrorForm,
              })
            )
          )
        )
      )
    );
  });

  loadSuspensionSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadSuspensionSuccess),
      map(() => CarrierAction.loadHook())
    );
  });

  loadHook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadHook),
      mergeMap(() =>
        this.carrierService.getHooks().pipe(
          map(hooks => CarrierAction.loadHookSuccess({ hooks })),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              CarrierAction.loadFailureErrorForm({
                errorMessage: messageErrorForm,
              })
            )
          )
        )
      )
    );
  });

  loadHookSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.loadHookSuccess),
      map(() => CarrierAction.intiCarrierSuccess())
    );
  });

  saveCarrier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.saveCarrier),
      mergeMap(action =>
        this.carrierService.addCarrier(action.carrier).pipe(
          map(() =>
            CarrierAction.saveCarrierSuccess({
              successMessage: messageSuccessCarrierSubmit,
            })
          ),
          tap(() =>
            setTimeout(() => {
              this.router.navigate(['/list-vehicle'], {
                queryParams: { selectedTabIndex: 0 },
              });
            }, 2000)
          ),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              CarrierAction.saveCarrierError({
                error: messageErrorSubmit,
              })
            )
          )
        )
      )
    );
  });

  updateCarrier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierAction.updateCarrier),
      mergeMap(action =>
        this.carrierService.updateCarrier(action.carrier).pipe(
          map(() =>
            CarrierAction.updateCarrierSuccess({
              successMessage: messageSuccessCarrierSubmit,
            })
          ),
          tap(() =>
            setTimeout(() => {
              this.router.navigate(['/list-vehicle'], {
                queryParams: { selectedTabIndex: 0 },
              });
            }, 2000)
          ),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              CarrierAction.updateCarrierError({
                error: messageErrorSubmit,
              })
            )
          )
        )
      )
    );
  });
}
