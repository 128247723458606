import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Notify } from '@app/core/types';

export const NotifyAction = createActionGroup({
  source: 'Notify',
  events: {
    'load notify': emptyProps(),
    'load notify success': props<{ notify: Notify[] }>(),
    'load notify error': props<{ error: string }>(),
    'save notify': props<{ notify: Notify }>(),
    'save notify success': props<{ notify: Notify }>(),
    'save notify error': props<{ error: string }>(),
  },
});
