import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Silhouette } from '@app/core/types';
import { CenterAxleTrailer } from '@app/core/models/center-axle-trailer';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CenterAxleTrailerService {
  constructor(private http: HttpClient) {}

  addCenterAxleTrailer(
    centerAxleTrailer: CenterAxleTrailer
  ): Observable<CenterAxleTrailer> {
    const url = `${environment.apiUrl}/api/center_axle_trailers`;
    return this.http.post<CenterAxleTrailer>(url, centerAxleTrailer);
  }

  getSilhouettes(): Observable<Silhouette[]> {
    const url = `${environment.apiUrl}/api/silhouette_center_axle_trailer_enums`;
    return this.http.get<string[]>(url).pipe(
      map((silhouettes: string[]) =>
        silhouettes.map(silhouette => {
          return {
            name: `${silhouette}`,
            img: `${silhouette
              .toLowerCase()
              .replaceAll(' ', '')
              .replaceAll(' ', '-')
              .replace('', 'center-axle-trailer-')}.svg`,
            alt: `${silhouette}`,
          };
        })
      )
    );
  }
  updateCenterAxleTrailer(
    centerAxleTrailer: CenterAxleTrailer
  ): Observable<CenterAxleTrailer> {
    const url = `${environment.apiUrl}/api/center_axle_trailers/${centerAxleTrailer.id}`;
    return this.http.put<CenterAxleTrailer>(url, centerAxleTrailer);
  }
}
