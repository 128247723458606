import { Component } from '@angular/core';
import { faCalculator } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { faTruckArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Store } from '@ngrx/store';
import { AuthenticationsAction } from '@app/store/actions';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  faCalculator = faCalculator;
  faPlus = faPlus;
  faArrowRightFromBracket = faArrowRightFromBracket;
  faTruckArrowRight = faTruckArrowRight;
  iconSelected = 'calculator';

  constructor(private store: Store) {}

  activeIcon(iconIndice: string) {
    this.iconSelected = iconIndice;
  }

  logout() {
    this.store.dispatch(AuthenticationsAction.goToLogin());
  }
}
