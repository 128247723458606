import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  authenticationFeatureKey,
  AuthenticationState,
} from '@app/store/reducers/authentication.reducer';

const selectState = createFeatureSelector<AuthenticationState>(
  authenticationFeatureKey
);
export const selectLoadingAuthentication = createSelector(
  selectState,
  (state: AuthenticationState) => state.loading
);
export const selectErrorMessageAuthentication = createSelector(
  selectState,
  (state: AuthenticationState) => state.errorMessage
);
export const selectIsAuthenticated = createSelector(
  selectState,
  (state: AuthenticationState) => state.isAuthenticated
);
