import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CarrierAction } from '@app/store/actions/carrier.actions';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { TypedAction } from '@ngrx/store/src/models';
import { AuthenticationsAction } from '@app/store/actions';
import { VehicleService } from '@app/core/services/vehicle.service';
import { VehicleAction } from '@app/store/actions/vehicle.actions';
import {
  messageErrorForm,
  messageErrorSubmit,
  messageSuccessUpdateConvoy,
} from '@app/core/constants/messages';
import { ErrorsUtils } from '@app/core/utils/errors-utils';
import { Router } from '@angular/router';

const UNAUTHORIZED_CODE_ERROR = 401;

@Injectable()
export class VehicleEffects {
  constructor(
    private actions$: Actions,
    private vehicleService: VehicleService,
    private router: Router
  ) {}

  private _catchErrorForm(
    error: string | HttpErrorResponse | Error,
    action: TypedAction<string> = CarrierAction.loadFailureErrorForm({
      errorMessage: messageErrorForm,
    })
  ) {
    if (
      error instanceof HttpErrorResponse &&
      error.status == UNAUTHORIZED_CODE_ERROR
    ) {
      return of(AuthenticationsAction.goToLogin());
    }
    return of(action);
  }

  loadVehicleComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleAction.loadVehiclesComponent),
      map(() => VehicleAction.loadCarriers())
    );
  });

  loadCarriers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleAction.loadCarriers),
      mergeMap(() =>
        this.vehicleService.getCarriers().pipe(
          map(carriers => VehicleAction.loadCarriersSuccess({ carriers })),
          catchError(error => this._catchErrorForm(error))
        )
      )
    );
  });

  loadCarriersSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleAction.loadCarriersSuccess),
      map(() => VehicleAction.loadCenterAxleTrailers())
    );
  });

  loadCenterAxleTrailers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleAction.loadCenterAxleTrailers),
      mergeMap(() =>
        this.vehicleService.getCenterAxleTrailers().pipe(
          map(centerAxleTrailers =>
            VehicleAction.loadCenterAxleTrailersSuccess({ centerAxleTrailers })
          ),
          catchError(error => this._catchErrorForm(error))
        )
      )
    );
  });

  loadCenterAxleTrailersSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleAction.loadCenterAxleTrailersSuccess),
      map(() => VehicleAction.loadDrawbarTrailers())
    );
  });

  loadDrawBarTrailers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleAction.loadDrawbarTrailers),
      mergeMap(() =>
        this.vehicleService.getDrawBarTrailers().pipe(
          map(drawBarTrailers =>
            VehicleAction.loadDrawbarTrailersSuccess({ drawBarTrailers })
          ),
          catchError(error => this._catchErrorForm(error))
        )
      )
    );
  });
  saveConvoy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleAction.saveConvoy),
      mergeMap(action =>
        this.vehicleService.saveConvoy(action.convoy).pipe(
          map(result => VehicleAction.saveConvoySuccess({ result })),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              VehicleAction.saveConvoyError({
                error: messageErrorSubmit,
              })
            )
          )
        )
      )
    );
  });
  getConvoy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleAction.loadConvoy),
      mergeMap(() =>
        this.vehicleService.getConvoys().pipe(
          map(convoy => VehicleAction.loadConvoySuccess({ convoy })),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              VehicleAction.saveConvoyError({
                error: messageErrorSubmit,
              })
            )
          )
        )
      )
    );
  });

  updateConvoy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleAction.updateConvoy),
      mergeMap(action =>
        this.vehicleService.updateConvoy(action.convoy).pipe(
          map(result =>
            VehicleAction.updateConvoySuccess({
              result,
              successMessage: messageSuccessUpdateConvoy,
            })
          ),
          tap(() =>
            setTimeout(() => {
              this.router.navigate(['/convoys'], {
                queryParams: { selectedTabIndex: 0 },
              });
            }, 2000)
          ),
          catchError(error =>
            ErrorsUtils.catchErrorFormWithRedirection(
              error,
              VehicleAction.updateConvoyError({
                error: messageErrorSubmit,
              })
            )
          )
        )
      )
    );
  });
}
