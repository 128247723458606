import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  Brand,
  Coupling,
  Hook,
  Silhouette,
  Standard,
  Suspension,
} from '@app/core/types';
import { Carrier } from '@app/core/models';

export const CarrierAction = createActionGroup({
  source: 'Carrier',
  events: {
    'load carrier component': emptyProps(),
    'inti carrier success': emptyProps(),
    'load brand': emptyProps(),
    'load brand success': props<{ brands: Brand[] }>(),
    'load silhouette': emptyProps(),
    'load silhouette success': props<{ silhouettes: Silhouette[] }>(),
    'load suspension': emptyProps(),
    'load suspension success': props<{ suspensions: Suspension[] }>(),
    'load standard': emptyProps(),
    'load standard success': props<{ standards: Standard[] }>(),
    'load coupling': emptyProps(),
    'load coupling success': props<{ couplings: Coupling[] }>(),
    'load hook': emptyProps(),
    'load hook success': props<{ hooks: Hook[] }>(),
    'select hook by coupling': props<{ couplingName: string }>(),
    'load failure error form': props<{ errorMessage: string }>(),
    'save carrier': props<{ carrier: Carrier }>(),
    'save carrier success': props<{ successMessage: string }>(),
    'save carrier error': props<{ error: string }>(),
    'update carrier': props<{ carrier: Carrier }>(),
    'update carrier success': props<{ successMessage: string }>(),
    'update carrier error': props<{ error: string }>(),
    'destroy carrier': emptyProps(),
  },
});
