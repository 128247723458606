import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Notify } from '@app/core/types';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private BASE_URL = environment.apiUrl + '/api';
  constructor(private http: HttpClient) {}

  postNotify(notify: Notify): Observable<Notify> {
    const url = `${this.BASE_URL}/notifies`;
    return this.http.post<Notify>(url, notify);
  }
}
