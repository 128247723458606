import { CenterAxleTrailer } from '@app/core/models/center-axle-trailer';
import { DrawBarTrailer } from '@app/core/models/draw-bar-trailer';
import { Carrier } from '@app/core/models/carrier';
import { Result } from '@app/core/types';

export class Convoy {
  id?: number;
  title?: string;
  description?: string;
  drawbarTrailer?: DrawBarTrailer;
  centerAxleTrailer?: CenterAxleTrailer;
  carrier?: Carrier;
  trackingNumber?: string;
  result?: Result;
  caseNumber?: string;
  rentalDateStart?: string | Date;
  rentalDateEnd?: string | Date;
}
