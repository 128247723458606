import { Pipe, PipeTransform } from '@angular/core';

type AbstractType<T> = abstract new (...arg: unknown[]) => T;

@Pipe({
  name: 'instanceof',
  pure: true,
})
export class InstanceofPipe implements PipeTransform {
  transform<V, R>(value: V, type: AbstractType<R>): boolean {
    return value instanceof type;
  }
}
