import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { NavBarComponent } from './components';
import { InstanceofPipe } from './pipes/instanceof.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GenerateUrlCarrierPipe } from '@app/shared/pipes/generate-url-carrier.pipe';
import { GenerateUrlCenterAxleTrailerPipe } from '@app/shared/pipes/generate-url-center-axle-trailer.pipe';
import { GenerateUrlDrawbarTrailerPipe } from '@app/shared/pipes/generate-url-drawbar.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MatGridListModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FontAwesomeModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatRadioModule,
    MatTabsModule,
    MatCardModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatDialogModule,
    MatProgressBarModule,
  ],
  declarations: [
    NavBarComponent,
    InstanceofPipe,
    GenerateUrlCarrierPipe,
    GenerateUrlCenterAxleTrailerPipe,
    GenerateUrlDrawbarTrailerPipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NavBarComponent,
    MatButtonModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatRadioModule,
    MatTabsModule,
    MatCardModule,
    InstanceofPipe,
    MatCheckboxModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatProgressBarModule,
    GenerateUrlCarrierPipe,
    GenerateUrlCenterAxleTrailerPipe,
    GenerateUrlDrawbarTrailerPipe,
  ],
})
export class ShareModule {}
