import { Brand, Silhouette, Standard } from '@app/core/types';
import { createReducer, on } from '@ngrx/store';
import { DrawBarTrailerActions } from '@app/store/actions/draw-bar-trailer.actions';

export const drawBarTrailerFeatureKey = 'drawBarTrailer';
export const TRAILER = 'Remorque';

export interface drawBarTrailerState {
  brands: Brand[];
  standards: Standard[];
  silhouettes: Silhouette[];
  errorMessage?: string;
  successMessage?: string;
  loadingSubmitDrawBarTrailer: boolean;
  loadingInitDrawbarTrailerForm: boolean;
}

export const initialState: drawBarTrailerState = {
  brands: [],
  standards: [],
  silhouettes: [],
  loadingSubmitDrawBarTrailer: false,
  loadingInitDrawbarTrailerForm: true,
};

export const drawBarTrailerReducer = createReducer(
  initialState,
  on(
    DrawBarTrailerActions.loadBrandSuccess,
    (state: drawBarTrailerState, { brands }): drawBarTrailerState => ({
      ...state,
      brands: brands.filter(brand => brand.typeBrand === TRAILER),
    })
  ),
  on(
    DrawBarTrailerActions.loadStandardSuccess,
    (state: drawBarTrailerState, { standards }): drawBarTrailerState => ({
      ...state,
      standards: standards,
    })
  ),
  on(
    DrawBarTrailerActions.loadSilhouetteSuccess,
    (state: drawBarTrailerState, { silhouettes }): drawBarTrailerState => ({
      ...state,
      silhouettes,
    })
  ),
  on(
    DrawBarTrailerActions.loadFailureErrorForm,
    (state: drawBarTrailerState, { errorMessage }): drawBarTrailerState => ({
      ...state,
      errorMessage,
    })
  ),
  on(
    DrawBarTrailerActions.saveDrawBarTrailer,
    (state: drawBarTrailerState): drawBarTrailerState => ({
      ...state,
      loadingSubmitDrawBarTrailer: true,
    })
  ),
  on(
    DrawBarTrailerActions.saveDrawBarTrailerSuccess,
    (state: drawBarTrailerState, { successMessage }): drawBarTrailerState => ({
      ...state,
      loadingSubmitDrawBarTrailer: false,
      successMessage: successMessage,
    })
  ),
  on(
    DrawBarTrailerActions.saveDrawBarTrailerError,
    (state: drawBarTrailerState, { error }): drawBarTrailerState => ({
      ...state,
      errorMessage: error,
      loadingSubmitDrawBarTrailer: false,
    })
  ),
  on(
    DrawBarTrailerActions.initDrawBarTrailerSuccess,
    (state: drawBarTrailerState): drawBarTrailerState => ({
      ...state,
      loadingInitDrawbarTrailerForm: false,
    })
  ),
  on(
    DrawBarTrailerActions.destroyDrawBarTrailer,
    (state: drawBarTrailerState): drawBarTrailerState => ({
      ...state,
      loadingInitDrawbarTrailerForm: true,
      errorMessage: undefined,
    })
  ),
  on(
    DrawBarTrailerActions.updateDrawBarTrailerSuccess,
    (state: drawBarTrailerState, { successMessage }): drawBarTrailerState => ({
      ...state,
      loadingSubmitDrawBarTrailer: false,
      successMessage: successMessage,
    })
  ),
  on(
    DrawBarTrailerActions.updateDrawBarTrailerError,
    (state: drawBarTrailerState, { error }): drawBarTrailerState => ({
      ...state,
      loadingSubmitDrawBarTrailer: false,
      errorMessage: error,
    })
  )
);
