import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Standard } from '@app/core/types';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class StandardService {
  constructor(private http: HttpClient) {}

  getStandards(): Observable<Standard[]> {
    const url = `${environment.apiUrl}/api/standards`;
    return this.http.get<Standard[]>(url);
  }
}
