import { Injectable } from '@angular/core';
import { from, map, mergeMap, Observable, reduce } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CenterAxleTrailer, DrawBarTrailer } from '@app/core/models';
import { environment } from '@env/environment';
import { Convoy } from '@app/core/models/convoy';
import { Carrier } from '@app/core/types/carrier';

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  private BASE_URL = environment.apiUrl + '/api';

  constructor(private http: HttpClient) {}

  getCarriers(): Observable<Carrier[]> {
    const url = `${this.BASE_URL}/carriers`;
    return this.http.get<Carrier[]>(url);
  }

  getCenterAxleTrailers(): Observable<CenterAxleTrailer[]> {
    const url = `${this.BASE_URL}/center_axle_trailers`;
    return this.http.get<CenterAxleTrailer[]>(url).pipe(
      map(from),
      mergeMap(value => value),
      map(json => {
        const updatedJson = { ...json };
        return Object.assign(new CenterAxleTrailer(), updatedJson);
      }),
      reduce((acc: CenterAxleTrailer[], val: CenterAxleTrailer) => {
        acc.push(val);
        return acc;
      }, [])
    );
  }

  getDrawBarTrailers(): Observable<DrawBarTrailer[]> {
    const url = `${this.BASE_URL}/drawbar_trailers`;
    return this.http.get<DrawBarTrailer[]>(url).pipe(
      map(from),
      mergeMap(value => value),
      map(json => {
        const updatedJson = { ...json };
        return Object.assign(new DrawBarTrailer(), updatedJson);
      }),
      reduce((acc: DrawBarTrailer[], val: DrawBarTrailer) => {
        acc.push(val);
        return acc;
      }, [])
    );
  }

  saveConvoy(convoy: Convoy): Observable<Convoy> {
    const url = `${this.BASE_URL}/convoys`;
    return this.http.post<Convoy>(url, convoy);
  }

  getConvoys(): Observable<Convoy[]> {
    const url = `${environment.apiUrl}/api/convoys`;
    return this.http.get<Convoy[]>(url);
  }

  updateConvoy(convoy: Convoy): Observable<Convoy> {
    const url = `${this.BASE_URL}/convoys/${convoy.id}`;
    return this.http.put<Convoy>(url, convoy);
  }
}
