import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Brand, Silhouette, Standard } from '@app/core/types';
import { CenterAxleTrailer } from '@app/core/models';

export const CenterAxleTrailerActions = createActionGroup({
  source: 'centerAxleTrailer',
  events: {
    'load center axle trailer component': emptyProps(),
    'init center axle trailer success': emptyProps(),
    'load brand': emptyProps(),
    'load brand success': props<{ brands: Brand[] }>(),
    'load silhouette': emptyProps(),
    'load silhouette success': props<{ silhouettes: Silhouette[] }>(),
    'load standard': emptyProps(),
    'load standard success': props<{ standards: Standard[] }>(),
    'load failure error form': props<{ errorMessage: string }>(),
    'save center axle trailer': props<{
      centerAxleTrailer: CenterAxleTrailer;
    }>(),
    'save center axle trailer success': props<{ successMessage: string }>(),
    'save center axle trailer error': props<{ error: string }>(),
    'update center axle trailer': props<{
      centerAxleTrailer: CenterAxleTrailer;
    }>(),
    'update center axle trailer success': props<{ successMessage: string }>(),
    'update center axle trailer error': props<{ error: string }>(),
    'destroy center axle trailer': emptyProps(),
  },
});
