export const messageErrorForm =
  'Erreur, impossible de charger les données, merci de réactualiser la page du formulaire';
export const messageErrorSubmit =
  "Erreur, impossible d' enregistrer le formulaire";

export const messageSuccessCarrierSubmit = 'Le porteur a bien été ajouté ! ';
export const messageSuccessCenterAxleTrailerSubmit =
  'La remorque à essieux médian a bien été ajoutée ! ';

export const messageSuccessDrawbarTrailerSubmit =
  "La remorque à rond d'avant-train médian a bien été ajoutée ! ";

export const OTHER = 'AUTRE';

export const messageSuccessUpdateConvoy = 'Le convoi a bien été mis à jour ! ';
