import { Brand, Hook, Standard } from '@app/core/types';

export class Carrier {
  brand?: Brand;
  coupling?: string;
  hook?: Hook;
  licencePlate?: string;
  mmta?: string;
  otherBrand?: string;
  otherInformation?: string;
  otherSilhouette?: string;
  otherStandard?: string;
  serialNumber?: string;
  silhouette?: string;
  standard?: Standard;
  suspension?: string;
  id?: string;
}
